import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {Link} from 'react-router-dom';
import { AuthContext } from '../common/Context'
import {BrowserRouter, Route, useParams} from "react-router-dom";
import Button from '@mui/material/Button';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from "react-router-dom";




function Copyright() {
    return (
      <Typography variant="body2" color="text.secondary">
        {'Copyright © '}
        <Link color="inherit" href="https://marketplex.com/">
        marketplex.com
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }

export default function Home() {
    let {id} = useParams();
  let navigate = useNavigate();
  const user = React.useContext(AuthContext);
  const userDetail = user.userDetail
  console.log('User', user)
  const logoutHandler = () => {
    user.setUserToken(null)
    navigate('/create-a-marketplace/sign-up')
  }

  return (
    <Box style={{textAlign:"center",marginTop:200}}>
     <Link to="/create-a-marketplace/sign-up">
        <Button variant="outlined">
            Sign Up
        </Button>
     </Link>
     <Link to="/create-a-marketplace/sign-in">
        <Button variant="outlined" style={{marginLeft:50}}>
            Sign In
        </Button>
     </Link>
  </Box>
  )
}
